.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  composes: mb-10 from global;
  width: 1240px;
  max-height: calc(100% - 208px);
  align-self: center;
  display: flex;
  flex-direction: column;
}

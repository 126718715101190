.container {
  margin-bottom: 40px;
}

.container:last-child {
  margin-bottom: 0;
}

.heading {
  composes: mb-6 text text_type_main-medium from global;
}

.gallery {
  composes: pl-4 from global;
  margin-top: 0;
  list-style: none;  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
  column-gap: 24px;
}

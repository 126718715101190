@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-1turn);
  }
}

.container {
  composes: pt-4 pb-30 from global;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.id {
  composes: text text_type_digits-large from global;
  text-shadow: 0px 4px 32px rgba(51, 51, 255, 0.50), 0px 0px 8px rgba(51, 51, 255, 0.25), 0px 0px 16px rgba(51, 51, 255, 0.25);
}

.description {
  composes: pt-8 text text_type_main-medium from global;
  text-align: center;
}

.icon {
  composes: pt-15 pb-15 from global;
}

.rotating {
  animation-name: rotation;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.textArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.text {
  composes: text text_type_main-default from global;
}

.inactive {
  composes: text_color_inactive from global;
}

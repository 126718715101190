.heading {
  composes: mb-6 text text_type_main-medium from global;
  text-align: center;
  margin-top: 140px;
}

.form {
  composes: mb-20 from global;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.tips {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tip {
  composes: text text_type_main-default text_color_inactive from global;
  text-align: center;
}

.link {
  display: inline;
  text-decoration: none;
  composes: text_color_accent from global;
}

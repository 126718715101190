@import-normalize;

.root {
  min-width: 1400px;
}

.modals {
  position: relative;
}

.ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.anchor {
  text-decoration: none;
  background-color: inherit;
}

.button {
  border: none;
  cursor: pointer;
  background-color: transparent;  
}

.container {
  composes: mt-20 pl-10 pt-10 pr-10 from global;

  width: 100%;
  max-width: 720px;
  height: min-content;
  box-sizing: border-box;
  align-self: center;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  min-height: 64px;  
  margin: 0;
  padding: 0;
  composes: text text_type_main-large from global;
}

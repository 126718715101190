.container {
  display: flex;
  gap: 60px;
  max-height: 100%;
}

.aside {
  display: flex;
  flex-direction: column;
  gap: 80px;
  min-width: 320px;
  max-width: 320px;
}

.link {
  min-height: 64px;
  color: #8585AD;  
  composes: anchor text text_type_main-medium from global;
  display: flex;
  align-items: center;
}

.active {
  color: white;
}

.text {
  margin: 0;
  padding: 0;
}

.description {
  margin: 0;
  padding: 0;
  composes: text text_type_main-default text_color_inactive from global;
}

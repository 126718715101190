.thumbnailText {
  composes: text text_type_main-default from global;
  text-align: center;
  margin: 0;
  padding: 0;
}

.row {
  composes: pl-8 pr-4 from global;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stickToBottom {
  align-self: flex-end;
}

.container {
  composes: pt-10 pb-10 from global;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.number {
  composes: text text_type_digits-default from global;
  align-self: center;
}

.name {
  composes: mt-10 text text_type_main-medium from global;
}

.status {
  composes: mt-3 text text_type_main-default from global;
}

.statusDone {
  composes: text_color_success from global;
}

.header {
  composes: mt-15 mb-6 text text_type_main-medium from global;
}

.scrollableContainer {
  composes: pr-4 custom-scroll from global;
  overflow: scroll;
}

.scrollableContainer::-webkit-scrollbar {
  height: 0;
  width: 8px;    
}

.contains {
  composes: ul from global;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ingredient {
  display: flex;
  align-items: center;
  gap: 16px;
}

.title {
  composes: text text_type_main-default from global;
  flex-grow: 1;
}

.price {
  composes: text text_type_digits-default from global;
  display: flex;
  align-items: center;
  gap: 8px;
}

.footer {
  composes: mt-10 from global;
  display: flex;
  justify-content: space-between;
}

.date {
  composes: text text_type_main-default text_color_inactive from global;
}

.tabs {
  composes: mb-10 from global;
  margin-top: 0;
  padding: 0;
  list-style: none;  
  display: flex;
}

.content {
  composes: pr-2 custom-scroll from global;
  overflow: scroll;
}

.content::-webkit-scrollbar {
  height: 0;
  width: 8px;    
}

.content::-webkit-scrollbar-thumb {
  border: none;
  border-radius: 0;
};

.overlay {
  width: 100vw;
  height: 100vh;  

  z-index: 1;
  position: absolute;  
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nutrition {
  display: flex;
  flex-direction: column;
  align-items: center;    
  gap: 8px;
  min-width: 112px;
}

.text {
  composes: text text_color_inactive from global;
}

.name {
  composes: text_type_main-default from global;
}

.value {
  composes: text_type_digits-default from global;
}

.link {
  composes: anchor from global;
  color: inherit;
}

.container {
  position: relative;
}

.isDragging {
  opacity: 0;
}

.figure {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.image {
  composes: pl-4 pr-4 from global;
  width: 100%;
  box-sizing: border-box;
}

.price {
  composes: m-2 text text_type_digits-default from global;
  display: flex;
  align-items: center;
  gap: 8px
}

.name {
  composes: text text_type_main-default from global;
  text-align: center;
  min-height: 48px;
}

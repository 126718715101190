.header { 
  composes: m-10 p-4 from global;
  background-color: #1C1C21;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 1280px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "menuArea logoArea profileArea";
}

.menuArea {
  grid-area: menuArea;
}

.logoArea {
  grid-area: logoArea;
  display: flex;
  justify-content: center;
}

.profileArea {
  grid-area: profileArea;
}

.section {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 8px;
}

.stickToRight {
  justify-content: flex-end;
}

.text {
  composes: text text_type_main-default from global;
  display: flex;
  align-items: center;
  gap: 8px;
}

.inactive {
  composes: text_color_inactive from global;
}

.link {
  composes: pt-4 pb-4 pr-5 pl-5 anchor from global;
  color: inherit;
  display: flex;
  gap: 8px;
  border-radius: 40px;
  cursor: pointer;
  border: none;
}

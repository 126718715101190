.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}

.isDragging {
  opacity: 0;
}

.thumbnailText {
  composes: text text_type_main-default from global;
  text-align: center;
  margin: 0;
  padding: 0;
}

.shadowWrapper {
  composes: pl-4 pt-4 pb-4 from global;
  border-radius: 40px;
}

.welcomingShadow {
  box-shadow: 0px 0px 40px #4C4CFF; 
}

.content {
  composes: ul from global;
  display: grid;
  grid-auto-rows: min-content 1fr min-content;
  gap: 16px;
  height: 100%;  
}

.scrollableContentContainer {
  composes: pr-2 custom-scroll from global;
  overflow: scroll;
  display: flex;
  flex-direction: column;  
  justify-content: center;  
}

.scrollableContentContainer::-webkit-scrollbar {
  height: 0;
  width: 8px;  
}

.scrollableContentContainer::-webkit-scrollbar-thumb {
  border: none;
  border-radius: 0;
}

.scrollableContent {
  composes: ul from global;
  display: flex;
  flex-direction: column;  
  gap: 16px;  
}

.summary {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}

.price {
  composes: text text_type_digits-medium from global;
  display: flex;
  align-items: center;
  gap: 8px
}

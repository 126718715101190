.form {
  width: min-content;  
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.button {
  align-self: center;
}

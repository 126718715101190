.heading {
  composes: text text_type_main-large from global;
  width: 100%;
  max-width: 1240px;
}

.content {
  composes: mt-5 from global;
  max-height: calc(100% - 60px);
  display: flex;
  justify-content: center;  
  gap: 40px;
}

.ingredients {
  display: flex;
  flex-direction: column;  
  max-width: 600px;
}

.cart {
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  gap: 40px;
  width: 600px;
}

.heading {
  composes: text text_type_main-large from global;
  width: 100%;
  max-width: 1240px;
}

.content {
  composes: mt-5 from global;
  max-height: calc(100% - 60px);
  display: flex;
  justify-content: center;  
  gap: 60px;
}

.feed {
  display: flex;
  flex-direction: column;  
  max-width: 600px;
}

.summary {
  display: flex;
  flex-direction: column;
  width: 580px;
}

.lists {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content auto;
  grid-auto-flow: column;
  gap: 24px 36px;
}

.listHeading {
  composes: text text_type_main-medium from global;
}

.totalHeading {
  composes: mt-15 text text_type_main-medium from global;
}

.digits {
  composes: text text_type_digits-large from global;
  text-shadow: 0px 4px 32px rgba(51, 51, 255, 0.50), 0px 0px 8px rgba(51, 51, 255, 0.25), 0px 0px 16px rgba(51, 51, 255, 0.25);
}

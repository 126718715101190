.border {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top right, #801AB2, #4C4CFF);
  border-radius: 100px;
  position: relative;
}

.background {
  background-color: #131316;  
  height: calc(100% - 4px);
  width: calc(100% - 4px);  
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.concealedBackground {
  background-color: #1C1C21;  
}

.image {
  object-fit: cover;
  height: 90%;
}

.concealedImage {
  opacity: 0.6;
}

.concealedCount {
  composes: text text_type_main-default from global;
  position: absolute;
  z-index: 1;
}
